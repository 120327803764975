import { clsx } from "clsx";

import { ApiResponse } from "@/api/rest/core/types";

export function ApiBanner({
  apiResponse,
  successMessage,
}: {
  apiResponse: ApiResponse<unknown>;
  successMessage: string;
}) {
  return (
    <Banner
      message={apiResponse.success ? successMessage : apiResponse.message}
      success={apiResponse.success}
    />
  );
}

export function Banner({
  message,
  success,
  triggerAnimation = false,
}: {
  message: string;
  success: boolean;
  triggerAnimation?: boolean;
}) {
  return (
    <div
      className={clsx(
        "p-2 text-center rounded-md transition-all duration-300",
        success ? "bg-green-200 text-green-900" : "bg-red-200 text-red-900",
        triggerAnimation && "animate-pulse border-2 border-red-500",
      )}
    >
      <span>{message}</span>
    </div>
  );
}
