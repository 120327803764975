import { DocumentTextIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ApiResponse } from "@/api/rest/core/types";
import UploadFilesPopup from "@/components/UploadFilesPopup.tsx";
import { Banner } from "@/components/common/Banner.tsx";
import Spinner from "@/components/common/Spinner.tsx";

interface SubmissionFormProps {
  children: ReactNode;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onPhoneChange: ((value: string) => void) | null;
  pendingFiles: File[];
  isFileUploadMandatory: boolean;
  isFileUploaded: boolean | null;
  isSubmittingCase: boolean;
  submitFiles: (files: File[]) => Promise<ApiResponse<unknown>>;
}

const SubmissionForm: React.FC<SubmissionFormProps> = ({
  children,
  handleSubmit,
  onPhoneChange,
  pendingFiles,
  isFileUploadMandatory,
  isFileUploaded,
  isSubmittingCase,
  submitFiles,
}) => {
  const [showAnimation, setShowAnimation] = React.useState(false);
  const { t } = useTranslation();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFileUploadMandatory && !isFileUploaded) {
      setShowAnimation(true);
      setTimeout(() => setShowAnimation(false), 2000); // Reset after 2 seconds
      return;
    }
    handleSubmit(e);
  };

  return (
    <form
      className="flex self-stretch flex-col gap-4"
      onSubmit={handleFormSubmit}
    >
      {children}
      {pendingFiles.length > 0 && (
        <div className="flex flex-row flex-wrap gap-2">
          {pendingFiles.map((file, i) => (
            <span
              key={i}
              className="rounded-md py-2 px-4 bg-gray-200 flex items-center"
            >
              <DocumentTextIcon className="w-4 h-4 inline -mt-0.5 mr-2" />
              {file.name}
            </span>
          ))}
        </div>
      )}
      {isFileUploadMandatory && !isFileUploaded && (
        <Banner
          success={false}
          message={t("warranty.noFilesUploaded")}
          triggerAnimation={showAnimation}
        />
      )}
      <UploadFilesPopup className="flex flex-row" uploadCallback={submitFiles}>
        <button
          className="rounded-md py-2 px-4 flex-grow !bg-orange-200 hover:!bg-orange-300 !text-orange-950"
          onClick={(event) => event.preventDefault()}
        >
          <PaperClipIcon className="w-4 h-4 inline -mt-1 ml-4 mr-1" />
          {t("warranty.addFiles")}
          <span className="w-4 h-4 inline ml-1 mr-4" />
        </button>
      </UploadFilesPopup>
      {onPhoneChange && (
        <input
          className="p-2 rounded"
          type="tel"
          minLength={7}
          onChange={(e) => onPhoneChange(e.target.value)}
          placeholder={t("warranty.contactPersonsPhoneNumber")}
          required
        />
      )}
      {!isSubmittingCase ? (
        <button
          type="submit"
          title={isFileUploadMandatory ? t("warranty.noFilesUploaded") : ""}
          className={`rounded-md py-2 px-4 ${
            isFileUploadMandatory && !isFileUploaded
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          } text-white`}
        >
          {t("warranty.submitCase")}
        </button>
      ) : (
        <span className="bg-orange-100 text-center rounded-md py-2 px-4 flex items-center justify-center">
          <Spinner className="w-5 h-5 inline mr-2" />
          {t("warranty.sendingCase")}
        </span>
      )}
    </form>
  );
};

export default SubmissionForm;
