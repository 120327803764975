import i18n from "@/i18n";

import { AccountInfo } from "@/api/rest/models/account";

import { changeLanguage } from "./changeLanguage";

export const initializeUserLanguage = async (account: AccountInfo) => {
  const currentLang = i18n.language || "en";
  const userLang = account.language;

  if (!userLang) {
    await changeLanguage(currentLang);
    return;
  }

  if (currentLang !== userLang) {
    await i18n.changeLanguage(userLang);
  }
};
