import { useCallback, useEffect, useState } from "react";

import { CaseType, Case } from "@/api/rest/models/warranty";
import {
  getAllCases,
  getAllCasesForBuilding,
} from "@/api/rest/services/warrantyService";

export const useWarrantyCases = (householdId: number, type?: CaseType) => {
  const [cases, setCases] = useState<Case[]>();

  const fetchData = useCallback(async () => {
    const data = await getAllCases(householdId, type);
    if (data.success) {
      setCases(
        data.result
          .slice()
          .sort((a, b) => b.createdAtTimestamp.diff(a.createdAtTimestamp)),
      );
    }
  }, [householdId, type]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, householdId, type]);

  return [cases, fetchData] as const;
};

export const useWarrantyCasesBuilding = (
  buildingId: number,
  type?: CaseType,
) => {
  const [cases, setCases] = useState<Case[]>();

  const fetchData = useCallback(async () => {
    const data = await getAllCasesForBuilding(buildingId, type);
    if (data.success) {
      setCases(
        data.result
          .slice()
          .sort((a, b) => b.createdAtTimestamp.diff(a.createdAtTimestamp)),
      );
    }
  }, [buildingId, type]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, buildingId, type]);

  return [cases, fetchData] as const;
};
