export const naturallySortByTitle = <T extends { title: string }>(
  items: T[],
  priorityTitles: string[] = [],
): T[] => {
  return [...items].sort((a, b) => {
    const isAPriority = priorityTitles.includes(a.title);
    const isBPriority = priorityTitles.includes(b.title);

    if (isAPriority && !isBPriority) {
      return 1;
    }
    if (!isAPriority && isBPriority) {
      return -1;
    }

    const aParts = a.title.split(/(\d+)/);
    const bParts = b.title.split(/(\d+)/);

    if (aParts[0] !== bParts[0]) {
      return a.title.localeCompare(b.title);
    }

    for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
      const aNum = parseInt(aParts[i]);
      const bNum = parseInt(bParts[i]);

      if (!isNaN(aNum) && !isNaN(bNum)) {
        if (aNum !== bNum) {
          return aNum - bNum;
        }
      } else if (aParts[i] !== bParts[i]) {
        return aParts[i].localeCompare(bParts[i]);
      }
    }
    return a.title.localeCompare(b.title);
  });
};
