import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { sendMessageToNative } from "@/api/react-native/bridge";
import { isMobileApp } from "@/utils/platform";

interface AppInitializerProps {
  onReady?: () => void;
}

export const WebviewInitializer = ({ onReady }: AppInitializerProps) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const signalAppReady = () => {
      sendMessageToNative({
        type: "APP_READY",
        payload: {
          ready: true,
        },
      });
      onReady?.();
    };

    const checkAppReady = () => {
      const intervalId = setInterval(() => {
        const isFetching = queryClient.isFetching();

        if (isFetching === 0) {
          clearInterval(intervalId);
          setTimeout(signalAppReady, 200);
        }
      }, 100);

      return () => clearInterval(intervalId);
    };

    if (isMobileApp()) {
      checkAppReady();
    }
  }, [queryClient, onReady]);

  return null; // This is a utility component that doesn't render anything
};
