import { Dayjs } from "@/components/dayjsExtended.ts";

export enum CaseType {
  WARRANTY = "WARRANTY",
  PROPERTY_MANAGER_CASE = "PROPERTY_MANAGER_CASE",
}

export interface CaseRaw {
  id: number;
  householdId: number;
  title: string;
  status: CaseStatus;
  createdAt: string;
  type: CaseType;
}

export interface Case extends CaseRaw {
  createdAtTimestamp: Dayjs;
  localisedStatus: string;
}

export enum CaseStatus {
  QUESTION_SENT = "QUESTION_SENT",
  RESPONSE_RECEIVED = "RESPONSE_RECEIVED",
  SOLVED = "SOLVED",
  CLOSED = "CLOSED",
}

export const statusMap = new Map<CaseType, Map<CaseStatus, string>>([
  [
    CaseType.WARRANTY,
    new Map([
      [CaseStatus.QUESTION_SENT, "warranty.caseStatus.questionSent"],
      [CaseStatus.RESPONSE_RECEIVED, "warranty.caseStatus.responseReceived"],
      [CaseStatus.SOLVED, "warranty.caseStatus.solved"],
      [CaseStatus.CLOSED, "warranty.caseStatus.closed"],
    ]),
  ],
  [
    CaseType.PROPERTY_MANAGER_CASE,
    new Map([
      [CaseStatus.QUESTION_SENT, "propertyManagerCase.caseStatus.questionSent"],
      [
        CaseStatus.RESPONSE_RECEIVED,
        "propertyManagerCase.caseStatus.responseReceived",
      ],
      [CaseStatus.SOLVED, "propertyManagerCase.caseStatus.solved"],
      [CaseStatus.CLOSED, "propertyManagerCase.caseStatus.closed"],
    ]),
  ],
]);

export interface CaseMessageRaw {
  id: number;
  warrantyCaseId: number;
  accountId: number;
  userId: number;
  userName: string;
  createdAt: string;
  content: string;
  type: CaseMessageType;
}

export interface CaseMessage extends CaseMessageRaw {
  createdAtTimestamp: Dayjs;
}

export enum CaseMessageType {
  TEXT = "TEXT",
  FILE = "FILE",
  IMAGE = "IMAGE",
  AI_HISTORY = "AI_HISTORY",
}

export interface CaseResponse {
  case: Case;
  messages: CaseMessage[];
}

export interface CaseMessageRequest {
  accountId: number;
  message: string;
}

export interface NewCaseRequest {
  question: string;
  householdId: number;
  startFresh: boolean;
  accountId: number;
  type: CaseType;
}

export interface SubmitCaseRequestWithoutFiles {
  title: string;
  description: string;
  householdId: number;
  accountId: number;
  phoneNumber: string | null;
  type: CaseType;
}

export interface UpdateCaseStatusRequest {
  buildingId: number;
  status: CaseStatus;
}

export enum NewWarrantyCaseResponseType {
  MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
  WARRANTY = "WARRANTY",
  NO_WARRANTY = "NO_WARRANTY",
}

export interface NewWarrantyCaseResponse {
  status: NewWarrantyCaseResponseType;
  message: string;
  caseId: number | null;
  provisionalTitle: string | null;
}
