import {
  ArrowDownTrayIcon,
  DocumentIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";

import dayjs from "dayjs";

import { debounce } from "lodash";

import { useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "next-i18next";

import { useMaintenance } from "@/api/hooks/useMaintenance.ts";
import { ApiResponse } from "@/api/rest/core/types";
import { Document } from "@/api/rest/models/document";
import { Product } from "@/api/rest/models/product";
import { downloadDocument2 } from "@/api/rest/services/documentService";
import { markMaintenanceDone } from "@/api/rest/services/maintenanceService";
import { KeyV } from "@/components/common/KeyV.tsx";
import { DocumentsPreview } from "@/components/household-contents/document/DocumentsPreview.tsx";
import ProductButton from "@/components/household-contents/ProductButton.tsx";
import UploadFilesPopup from "@/components/UploadFilesPopup.tsx";

export default function MaintenanceOverview({
  product,
  documents,
  updateHousehold,
}: {
  product: Product;
  documents: Document[];
  updateHousehold: () => void;
}) {
  const { t } = useTranslation();
  const [maintenance, updateMaintenance] = useMaintenance(product.id);
  const [checked, setChecked] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const markAsDoneDisabled =
    maintenance &&
    maintenance.history.length > 0 &&
    maintenance.history[0].performedAtTimestamp.isSame(dayjs(), "day");

  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const markAsDoneRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    markAsDoneRef.current = debounce(async () => {
      setEnabled(false);
      await markMaintenanceDone(
        product.id,
        pendingFiles.length > 0 ? pendingFiles[0] : null,
      );
      setPendingFiles([]);
      setShowTooltip(false);
      await updateMaintenance();
      setChecked(false);
    }, 1000);

    return () => {
      markAsDoneRef.current?.cancel();
    };
  }, [
    product.id,
    pendingFiles,
    setPendingFiles,
    setShowTooltip,
    updateMaintenance,
    setChecked,
    setEnabled,
  ]);

  const submitFiles = useCallback(
    async (files: File[]): Promise<ApiResponse<unknown>> => {
      setPendingFiles(files);
      return Promise.resolve({ success: true, result: null });
    },
    [],
  );

  if (!maintenance) {
    return <></>;
  }

  return (
    <div className="p-6 flex flex-col gap-6 overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
      <table className="w-full text-left table-auto border-collapse">
        <thead>
          <tr>
            <th className="px-4 py-2">
              {t("product.maintenancePopup.upcomingMaintenance")}
            </th>
            <th className="px-4 py-2">
              {t("product.maintenancePopup.addFile")}
            </th>
            <th className="px-4 py-2">{t("product.maintenancePopup.done")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-100 rounded-md my-2 ">
            <td className="border-b px-4 py-2">
              {(
                maintenance?.nextMaintenanceTimestamp ||
                product.nextMaintenanceTimestamp
              ).format("L")}
            </td>

            <td className="relative flex border-b px-4 py-2 ">
              {!markAsDoneDisabled && enabled ? (
                <UploadFilesPopup
                  className="flex flex-row"
                  uploadCallback={submitFiles}
                >
                  {pendingFiles.length === 0 ? (
                    <PaperClipIcon className="cursor-pointer w-6 h-6 text-orange-600 inline my-2" />
                  ) : (
                    <>
                      <DocumentIcon className="cursor-pointer w-6 h-6 text-orange-600 inline my-2" />
                      <span className="p-2 truncate w-28">
                        {pendingFiles[0].name}
                      </span>
                    </>
                  )}
                </UploadFilesPopup>
              ) : (
                <PaperClipIcon className="cursor-pointer w-6 h-6 text-gray-600 inline my-2" />
              )}
              {showTooltip && pendingFiles.length === 0 && (
                <div className="absolute right-full  mb-2 px-4 py-2 bg-red-200 text-red-900 text-sm rounded-lg shadow-lg">
                  {t("product.maintenancePopup.addFileTooltip")}
                </div>
              )}
            </td>
            <td className="border-b px-4 py-2 ">
              <input
                type="checkbox"
                className="text-orange-500 my-2 w-6 h-6"
                disabled={!enabled || markAsDoneDisabled}
                checked={checked}
                onChange={(event) => {
                  if (event.target.checked) {
                    setChecked(event.target.checked);
                    markAsDoneRef.current?.();
                  } else {
                    setChecked(false);
                    markAsDoneRef.current?.cancel();
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      {maintenance?.information && (
        <KeyV
          name={t("product.maintenancePopup.maintenanceGuide")}
          value={maintenance.information}
          useMarkdown
        />
      )}
      <div className="flex flex-row gap-4 flex-wrap">
        <KeyV
          name={t("product.maintenancePopup.maintenanceInterval")}
          className="flex-grow md:flex-grow-0"
          value={`${product.maintenanceIntervalDays} päeva`}
        />

        <div className="flex flex-row gap-4 flex-wrap">
          <ProductButton
            key={product.id}
            className={
              "flex flex-col py-2 px-4 flex-shrink-0 rounded-lg border-2 bg-white border-gray-300 hover:bg-orange-100"
            }
            product={product}
            documents={documents}
            updateHousehold={updateHousehold}
          >
            <div className="flex flex-row items-center">
              <span className="font-semibold">
                {t("product.maintenancePopup.relatedProduct")}
              </span>
            </div>
            <span>{product.name}</span>
          </ProductButton>
        </div>
      </div>
      {maintenance && maintenance.history.length !== 0 && (
        <table className="w-full text-left table-auto border-collapse">
          <thead>
            <tr>
              <th className="px-4 py-2">
                {t("product.maintenanceOverview.date")}
              </th>
              <th className="px-4 py-2">
                {t("product.maintenanceOverview.scheduledDate")}
              </th>
              <th className="px-4 py-2">
                {t("product.maintenanceOverview.document")}
              </th>
            </tr>
          </thead>
          <tbody>
            {maintenance &&
              maintenance.history.map((maintenanceHistory) => (
                <tr
                  className="my-2"
                  key={
                    maintenanceHistory.performedAtTimestamp.toString() +
                    maintenanceHistory.scheduledForTimestamp.toString() +
                    maintenanceHistory.document?.id
                  }
                >
                  <td className="border-b px-4 py-2">
                    {maintenanceHistory.performedAtTimestamp.format("L")}
                  </td>
                  <td
                    className={`border-b px-4 py-2 ${
                      maintenanceHistory.scheduledForTimestamp.isBefore(
                        maintenanceHistory.performedAtTimestamp,
                      )
                        ? "text-red-700"
                        : ""
                    }`}
                  >
                    {maintenanceHistory.scheduledForTimestamp.format("L")}
                  </td>
                  <td className="border-b px-4 py-2">
                    {maintenanceHistory.document && (
                      <DocumentsPreview
                        key={maintenanceHistory.document.id}
                        document={maintenanceHistory.document}
                        onSelect={downloadDocument2}
                        allowPreview
                      >
                        <ArrowDownTrayIcon className="cursor-pointer w-6 h-6 text-orange-600 inline my-2" />
                      </DocumentsPreview>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
