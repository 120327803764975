import { useCallback } from "react";

import { isAppleMobileDevice } from "@/utils/platform";

import SignInWithAppleButton from "./AppleSignInButtonForWebVeiw";

interface AppleSignInButtonProps {
  onClick: () => void;
  isWebView: boolean;
}

export const AppleSignInButton = ({
  onClick,
  isWebView,
}: AppleSignInButtonProps) => {
  const handleClick = useCallback(async () => {
    if (isWebView) {
      onClick();
      return;
    }

    try {
      const data = await window.AppleID.auth.signIn();
      // In web flow, we get code directly from Apple
      if (data.authorization?.code) {
        window.handleAppleSignInResponse(data.authorization.code);
      }
    } catch (error) {
      console.error("Apple sign in failed:", error);
    }
  }, [onClick, isWebView]);

  if (isWebView && isAppleMobileDevice()) {
    return <SignInWithAppleButton onClick={handleClick} />;
  }
  // return (
  //   <div
  //     id="appleid-signin"
  //     data-mode="center-align"
  //     data-type="sign-in"
  //     data-color="black"
  //     data-border="false"
  //     data-border-radius="15"
  //     data-width="200"
  //     data-height="40"
  //     onClick={handleClick}
  //   />
  // );
};

export default AppleSignInButton;
