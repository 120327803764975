import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useTranslation } from "next-i18next";

const ConfirmationDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  message,
  confirmText,
  cancelText,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={() => {}} className="relative z-50">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel className="w-full max-w-md transform rounded-lg bg-white p-6 shadow-xl md:p-8">
          {/* Header Section */}
          <div className="mb-6 flex items-start justify-between">
            <DialogTitle className="text-xl font-semibold leading-6 text-gray-900">
              {title || t("notifications.confirmUnsubscribe")}
            </DialogTitle>
          </div>

          {/* Warning Icon and Message */}
          <div className="mb-8">
            <div className="mb-6 flex justify-center">
              <div className="rounded-full bg-red-100 p-3">
                <svg
                  className="h-6 w-6 text-red-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>
              </div>
            </div>
            <p className="text-center text-sm text-gray-500">
              {message || t("notifications.confirmUnsubscribeMessage")}
            </p>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col gap-3 sm:flex-row sm:justify-end">
            <button
              className="w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 sm:w-auto"
              onClick={onCancel}
            >
              {cancelText || t("common.cancel")}
            </button>
            <button
              className="w-full rounded-md bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 sm:w-auto"
              onClick={onConfirm}
            >
              {confirmText || t("notifications.unsubscribeConfirm")}
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
