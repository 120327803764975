import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect, useState } from "react";

const ClosableBanner = ({
  message,
  storageKey,
  success = false,
}: {
  message: ReactNode;
  storageKey: string;
  success?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const bannerState = localStorage.getItem(storageKey);
    if (bannerState === "hidden") {
      setIsVisible(false);
    }
  }, [storageKey]);

  const handleClose = () => {
    setIsClosing(true);
    // Wait for animation to complete before hiding
    setTimeout(() => {
      setIsVisible(false);
      localStorage.setItem(storageKey, "hidden");
    }, 300); // Match this with the CSS transition duration
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={`
        fixed top-0 left-0 right-0 z-50
        transform transition-all duration-300 ease-in-out
        ${isClosing ? "-translate-y-full opacity-0" : "translate-y-0 opacity-100"}
      `}
    >
      <div className="max-w-4xl mx-auto">
        <div
          className={`
            m-4 rounded-lg p-4 flex justify-between items-center shadow-lg
            ${success ? "bg-green-100" : "bg-blue-100"}
          `}
        >
          <span className={`${success ? "text-green-800" : "text-blue-800"}`}>
            {message}
          </span>
          <button
            onClick={handleClose}
            className="ml-4 p-1 hover:bg-black/10 rounded-full transition-colors"
            aria-label="Close banner"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClosableBanner;
