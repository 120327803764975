import React from "react";

interface SignInWithAppleButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const SignInWithAppleButton: React.FC<SignInWithAppleButtonProps> = ({
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      role="button"
      tabIndex={0}
      aria-label="Sign in with Apple"
      style={{
        fontSynthesis: "none",
        MozFontFeatureSettings: "kern",
        WebkitFontSmoothing: "antialiased",
        MozOsxFontSmoothing: "grayscale",
        width: "200px",
        height: "42px",
        minWidth: "130px",
        maxWidth: "375px",
        minHeight: "30px",
        maxHeight: "64px",
        position: "relative",
        letterSpacing: "initial",
      }}
    >
      {/* Overlay for SVG Text */}
      <div
        style={{
          paddingRight: "8%",
          paddingLeft: "8%",
          position: "absolute",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ pointerEvents: "none", overflow: "visible" }}
          width="100%"
          height="100%"
        >
          <g>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ overflow: "visible" }}
              width="100%"
              height="50%"
              y="25%"
              viewBox="0 -12 103.25625 15.8"
              fill="#fff"
            >
              <text
                fontSize="12px"
                fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif"
              >
                 Sign in with Apple
              </text>
            </svg>
          </g>
        </svg>
      </div>

      {/* Background Rectangle */}
      <div style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ overflow: "visible" }}
          width="100%"
          height="100%"
        >
          <rect width="100%" height="100%" ry="15%" fill="#000" />
        </svg>
      </div>
    </div>
  );
};

export default SignInWithAppleButton;
