import { apiBase, fetchUsingApi } from "@/api/rest/core/apiClient";
import { ApiResponse } from "@/api/rest/core/types";
import {
  CaseType,
  NewCaseRequest,
  NewWarrantyCaseResponse,
  SubmitCaseRequestWithoutFiles,
  UpdateCaseStatusRequest,
  Case,
  CaseMessage,
  CaseMessageRaw,
  CaseRaw,
  CaseResponse,
  CaseMessageRequest,
  statusMap,
} from "@/api/rest/models/warranty";
import dayjs from "@/components/dayjsExtended";

const enrichWarrantyCaseResponse = (response: CaseResponse): CaseResponse => {
  return {
    case: enrichWarrantyCase([response.case])[0],
    messages: enrichWarrantyCaseMessages(response.messages),
  };
};

const enrichWarrantyCaseMessage = (m: CaseMessageRaw): CaseMessage => {
  return {
    ...m,
    createdAtTimestamp: dayjs(m.createdAt),
  };
};

const enrichWarrantyCaseMessages = (
  messages: CaseMessageRaw[],
): CaseMessage[] => {
  return messages.map(enrichWarrantyCaseMessage);
};

const enrichWarrantyCase = (cases: CaseRaw[]): Case[] => {
  return cases.map((c) => ({
    ...c,
    createdAtTimestamp: dayjs(c.createdAt),
    localisedStatus:
      statusMap.get(c.type)?.get(c.status) ?? c.status.toString(),
  }));
};

export const getAllCases = async (
  householdId: number,
  type?: CaseType,
): Promise<ApiResponse<Case[]>> => {
  const typeParam = type ? `&type=${type}` : "";
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/list?householdId=${householdId}${typeParam}`,
    "GET",
    enrichWarrantyCase,
  );
};

export const getAllCasesForBuilding = async (
  buildingId: number,
  type?: CaseType,
): Promise<ApiResponse<Case[]>> => {
  const typeParam = type ? `&type=${type}` : "";
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/list/building?buildingId=${buildingId}${typeParam}`,
    "GET",
    enrichWarrantyCase,
  );
};

export const getCase = async (
  caseId: number,
): Promise<ApiResponse<CaseResponse>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/get`,
    "GET",
    enrichWarrantyCaseResponse,
  );
};

export const sendMessage = async (
  caseId: number,
  request: CaseMessageRequest,
): Promise<ApiResponse<CaseMessage>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/message`,
    "POST",
    enrichWarrantyCaseMessage,
    JSON.stringify(request),
  );
};

export const sendFiles = async (
  caseId: number,
  accountId: number,
  files: File[],
): Promise<ApiResponse<CaseMessage[]>> => {
  const formData = new FormData();
  formData.append("accountId", accountId.toString());
  files.forEach((file) => {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  });
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/files`,
    "POST",
    enrichWarrantyCaseMessages,
    formData,
  );
};

export const fileNewCase = async (
  request: NewCaseRequest,
): Promise<ApiResponse<NewWarrantyCaseResponse>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/new`,
    "POST",
    (e) => e as NewWarrantyCaseResponse,
    JSON.stringify(request),
  );
};

export const forceSubmitCase = async (
  request: SubmitCaseRequestWithoutFiles,
  files: File[],
): Promise<ApiResponse<CaseRaw>> => {
  const formData = new FormData();
  Object.entries(request).forEach(([key, value]) =>
    formData.append(key, (value as string | number).toString()),
  );
  files.forEach((file) => {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  });
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/submit`,
    "POST",
    (e) => e as CaseRaw,
    formData,
  );
};

export const updateStatus = async (
  caseId: number,
  request: UpdateCaseStatusRequest,
): Promise<ApiResponse<CaseRaw>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/status`,
    "PUT",
    (e) => e as CaseRaw,
    JSON.stringify(request),
  );
};
