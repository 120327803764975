import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { HouseholdData } from "@/api/rest/models/household";
import { forceSubmitCase } from "@/api/rest/services/warrantyService.ts";

import { Account } from "@/api/rest/models/account";

import { sendToSentry } from "@/utils/sentry";

import { CaseType } from "@/api/rest/models/warranty";

import SubmissionForm from "./SubmissionForm";
import { useSubmissionForm } from "./useSubmissionForm";

interface DirectSubmissionFormProps {
  householdData: HouseholdData;
  availableAccounts: Account[];
  refreshCases: () => void;
}

const DirectSubmissionForm: React.FC<DirectSubmissionFormProps> = ({
  householdData,
  availableAccounts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const caseRef = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);

  const {
    pendingFiles,
    isSubmittingCase,
    setSubmittingState,
    isFileUploaded,
    submitFiles,
  } = useSubmissionForm({ householdData });

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const value = caseRef?.current?.value.trim() ?? "";
      const selectedAccount = availableAccounts?.[0]?.id;
      if (!value || !selectedAccount) {
        return;
      }
      setSubmittingState(true);
      const result = await forceSubmitCase(
        {
          description: value,
          householdId: householdData.household.id,
          title: titleRef.current?.value ?? "Unknown",
          accountId: selectedAccount,
          phoneNumber: "",
          type: CaseType.WARRANTY,
        },
        pendingFiles,
      );
      if (result.success) {
        navigate(`/warranty/${result.result.id}`);
      } else {
        sendToSentry(new Error(result.message), {
          additionalContext: {
            component: "DirectSubmissionForm.submitForm",
            result,
          },
        });
        setSubmittingState(false);
      }
    },
    [
      availableAccounts,
      setSubmittingState,
      householdData.household.id,
      pendingFiles,
      navigate,
    ],
  );

  return (
    <>
      <span className="flex items-center">
        <h3 className="text-xl font-semibold inline">
          {t("warranty.forceSubmitCase")}
        </h3>
      </span>
      <SubmissionForm
        handleSubmit={handleSubmit}
        pendingFiles={pendingFiles}
        onPhoneChange={null}
        isFileUploadMandatory={false} // always false for DirectSubmissionForm
        isFileUploaded={isFileUploaded}
        isSubmittingCase={isSubmittingCase}
        submitFiles={submitFiles}
      >
        <input
          className="border border-gray-300 rounded-md p-2 w-full"
          name="title"
          placeholder={t("warranty.caseTitle")}
          ref={titleRef}
          required
        />
        <textarea
          className="border border-gray-300 rounded-md p-2 w-full"
          name="report"
          rows={5}
          placeholder={t("warranty.caseDescription")}
          ref={caseRef}
          required
        />
      </SubmissionForm>
    </>
  );
};

export default DirectSubmissionForm;
