// src/utils/platformUtils.ts

/**
 * Platform detection utilities for handling different environments
 * (web browser, React Native WebView, specific mobile platforms)
 */

/**
 * Checks if the application is running in a React Native WebView
 */
export const isMobileApp = (): boolean => {
  return Boolean(window.ReactNativeWebView);
};

/**
 * Checks if the application is running in an Android React Native WebView
 */
export const isAndroidMobileApp = (): boolean => {
  return isMobileApp() && /Android/i.test(navigator.userAgent);
};

/**
 * Checks if the application is running in an iOS React Native WebView
 */
export const isIOSMobileApp = (): boolean => {
  return isMobileApp() && /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

/**
 * Checks if the device is an Apple mobile device (iPhone, iPad, iPod)
 * Uses both UA detection and touch points to ensure it's a mobile device
 */
export const isAppleMobileDevice = (): boolean => {
  return (
    /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent) &&
    navigator.maxTouchPoints > 1
  );
};

/**
 * Checks if the current device is any type of mobile device
 * Uses UA data API if available, falls back to UA string and touch points
 */
export const isMobileDevice = (): boolean => {
  return (
    navigator.userAgentData?.mobile ||
    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) &&
      navigator.maxTouchPoints > 1)
  );
};

export const isDesktopSafari = () => {
  const ua = navigator.userAgent;
  return (
    ua.includes("Safari") &&
    !ua.includes("Chrome") &&
    !ua.includes("iPhone") &&
    !ua.includes("iPad") &&
    !ua.includes("iPod")
  );
};

/**
 * Represents all possible platform types the application can run on
 */
export type Platform =
  | "android-webview" // Android React Native WebView
  | "ios-webview" // iOS React Native WebView
  | "android-browser" // Android mobile browser
  | "ios-browser" // iOS mobile browser
  | "mobile-browser" // Other mobile browsers
  | "desktop-browser"; // Desktop browsers

/**
 * Gets detailed information about the current platform environment
 *
 * Priority order:
 * 1. WebView detection (most specific)
 * 2. Mobile device type detection
 * 3. General mobile detection
 * 4. Desktop fallback
 *
 * @returns Platform type identifier
 */
export const getPlatform = (): Platform => {
  // Check WebView environments first
  if (isAndroidMobileApp()) {
    return "android-webview";
  }
  if (isIOSMobileApp()) {
    return "ios-webview";
  }

  // Check mobile browsers
  if (isMobileDevice()) {
    if (/Android/i.test(navigator.userAgent)) {
      return "android-browser";
    }
    if (isAppleMobileDevice()) {
      return "ios-browser";
    }
    return "mobile-browser";
  }

  // Fallback to desktop
  return "desktop-browser";
};
