import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { HouseholdData } from "@/api/rest/models/household";
import {
  CaseType,
  NewWarrantyCaseResponse,
  NewWarrantyCaseResponseType,
} from "@/api/rest/models/warranty";
import {
  fileNewCase,
  forceSubmitCase,
} from "@/api/rest/services/warrantyService.ts";

import { Banner } from "@/components/common/Banner.tsx";
import {
  ChatMessage,
  ChatMessageHolder,
  ChatMessageType,
} from "@/components/common/ChatMessage.tsx";

import { Account } from "@/api/rest/models/account";

import { sendToSentry } from "@/utils/sentry";

import SubmissionForm from "./SubmissionForm";
import { useSubmissionForm } from "./useSubmissionForm";

interface ChatMessageItem extends NewWarrantyCaseResponse {
  isResponse: boolean;
}

interface ChatSubmissionProps {
  householdData: HouseholdData;
  availableAccounts: Account[];
  refreshCases: () => void;
  caseType: CaseType;
}

const ChatSubmission: React.FC<ChatSubmissionProps> = ({
  householdData,
  availableAccounts,
  refreshCases,
  caseType,
}) => {
  const { t } = useTranslation();

  const translationNamespace =
    caseType === CaseType.WARRANTY ? "warranty" : "propertyManagerCase";

  const navigate = useNavigate();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const caseRef = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneChange = (value: string) => {
    setPhoneNumber(value);
  };

  const {
    pendingFiles,
    isSubmittingCase,
    setSubmittingState,
    isFileUploaded,
    isFileUploadMandatory,
    submitFiles,
    resetSubmission,
  } = useSubmissionForm({ householdData });

  const [chatHistory, setChatHistory] = useState<ChatMessageItem[]>([]);

  const submitChatMessage = useCallback(
    async (isFreshChat: boolean) => {
      const question = inputRef.current?.value.trim() ?? "";
      const selectedAccount = availableAccounts?.[0]?.id;
      if (!question || !selectedAccount) {
        return;
      }

      inputRef.current!.value = "";
      setChatHistory((oldHistory) => [
        ...oldHistory,
        {
          status: NewWarrantyCaseResponseType.MORE_INFO_REQUIRED,
          message: question,
          caseId: null,
          isResponse: false,
          provisionalTitle: null,
        },
      ]);
      const result = await fileNewCase({
        question: question,
        householdId: householdData.household.id,
        startFresh: isFreshChat,
        accountId: selectedAccount,
        type: caseType,
      });
      if (result.success) {
        setChatHistory((oldHistory) => [
          ...oldHistory,
          {
            ...result.result,
            isResponse: true,
          },
        ]);
      }
    },
    [householdData, availableAccounts, caseType],
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const value = caseRef?.current?.value.trim() ?? "";
      const selectedAccount = availableAccounts?.[0]?.id;
      if (!value || !selectedAccount) {
        return;
      }

      setSubmittingState(true);
      const result = await forceSubmitCase(
        {
          description:
            value +
            ("\n\n" +
              t("warranty.contactPersonsPhoneNumber") +
              ": " +
              phoneNumber.trim()),
          householdId: householdData.household.id,
          title: titleRef.current?.value ?? "Unknown",
          accountId: selectedAccount,
          phoneNumber: phoneNumber.trim(),
          type: caseType,
        },
        pendingFiles,
      );
      if (result.success) {
        navigate(`/warranty/${result.result.id}`);
      } else {
        sendToSentry(new Error(result.message), {
          additionalContext: {
            component: "ChatSubmission.submitForm",
            result,
          },
        });
        setSubmittingState(false);
      }
    },
    [
      availableAccounts,
      setSubmittingState,
      t,
      phoneNumber,
      householdData.household.id,
      pendingFiles,
      navigate,
      caseType,
    ],
  );

  const currentStatus = useMemo(
    () => (chatHistory.length > 0 ? chatHistory[chatHistory.length - 1] : null),
    [chatHistory],
  );

  const resetChat = useCallback(() => {
    refreshCases();
    setChatHistory([]);
    resetSubmission();
  }, [refreshCases, resetSubmission]);

  return (
    <>
      <span className="flex items-center">
        <h3 className="text-xl font-semibold inline">
          {t(`${translationNamespace}.submitNewCase`)}
        </h3>
        {currentStatus && (
          <button
            className="ml-2 px-2 rounded !bg-slate-400 hover:!bg-slate-500"
            onClick={resetChat}
          >
            {t(`${translationNamespace}.cancel`)}
          </button>
        )}
      </span>
      <div className="prose prose-sm prose-gray text-gray-500">
        <div>
          <ul className="m-0">
            <li className="m-0">
              {t(`${translationNamespace}.submitOneCase`)}
            </li>
            {isFileUploadMandatory && (
              <li className="m-0">
                {t(`${translationNamespace}.mandatoryFile`)}
              </li>
            )}
            {isFileUploadMandatory && (
              <li className="m-0">
                {t(`${translationNamespace}.pictureDescription`)}
              </li>
            )}
            <li>
              <span className="inline-block">
                {t(`${translationNamespace}.problemDescriptionInstructions`)}
              </span>
              <ul className="m-0">
                <li className="m-0">
                  {t(`${translationNamespace}.defectDate`)}
                </li>
                <li className="m-0">
                  {t(`${translationNamespace}.defectLocation`)}
                </li>
                <li className="m-0">
                  {t(`${translationNamespace}.defectDescription`)}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {chatHistory.length > 0 && (
        <ChatMessageHolder>
          {chatHistory
            .filter(
              (m) =>
                m.status === NewWarrantyCaseResponseType.MORE_INFO_REQUIRED,
            )
            .map((m, i) => (
              <ChatMessage
                key={i}
                message={m.message}
                type={
                  m.isResponse
                    ? ChatMessageType.RESPONSE
                    : ChatMessageType.QUESTION
                }
              />
            ))}
          {currentStatus?.isResponse === false && (
            <ChatMessage
              className="animate-pulse font-bold px-4 py-1 text-lg"
              message={"..."}
              type={ChatMessageType.RESPONSE}
            />
          )}
        </ChatMessageHolder>
      )}
      {(!currentStatus ||
        currentStatus?.status ===
          NewWarrantyCaseResponseType.MORE_INFO_REQUIRED) && (
        <>
          <textarea
            className="border border-gray-300 rounded-md p-2 w-full whitespace-pre-wrap"
            name="report"
            rows={5}
            placeholder={t(`${translationNamespace}.describeCase`)}
            ref={inputRef}
          />
          {(!currentStatus || currentStatus?.isResponse) && (
            <button
              className="rounded-md py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white"
              onClick={() => submitChatMessage(currentStatus === null)}
            >
              {currentStatus?.status ===
              NewWarrantyCaseResponseType.MORE_INFO_REQUIRED
                ? t(`${translationNamespace}.answer`)
                : t(`${translationNamespace}.startSubmission`)}
            </button>
          )}
        </>
      )}
      {currentStatus &&
        currentStatus?.status !==
          NewWarrantyCaseResponseType.MORE_INFO_REQUIRED && (
          <>
            <hr className="border-2" />
            {currentStatus?.status ===
              NewWarrantyCaseResponseType.NO_WARRANTY && (
              <Banner
                success={false}
                message={t(`${translationNamespace}.notCovered`)}
              />
            )}
            {currentStatus?.status === NewWarrantyCaseResponseType.WARRANTY && (
              <Banner
                success
                message={t(`${translationNamespace}.descriptionIsSufficient`)}
              />
            )}
            <SubmissionForm
              handleSubmit={handleSubmit}
              pendingFiles={pendingFiles}
              onPhoneChange={handlePhoneChange}
              isFileUploadMandatory={isFileUploadMandatory}
              isFileUploaded={isFileUploaded}
              isSubmittingCase={isSubmittingCase}
              submitFiles={submitFiles}
            >
              <input
                className="hidden"
                type="hidden"
                value={currentStatus.provisionalTitle ?? "Unknown"}
                ref={titleRef}
              />
              <textarea
                className="border border-gray-300 rounded-md p-2 w-full"
                name="report"
                rows={5}
                placeholder={t(`${translationNamespace}.caseDescription`)}
                defaultValue={currentStatus.message}
                ref={caseRef}
                required
              />
            </SubmissionForm>
          </>
        )}
    </>
  );
};

export default ChatSubmission;
