import * as Sentry from "@sentry/react";
import { QueryClient } from "@tanstack/react-query";

import { useHouseholdsGlobal } from "@/api/hooks/useHouseholds";
import { sendMessageToNative } from "@/api/react-native/bridge";
import { fetchUsingApi, routerInstance } from "@/api/rest/core/apiClient";
import {
  ApiError,
  ApiResponse,
  BackendErrorResponse,
  getErrorMessage,
} from "@/api/rest/core/types";
import { debug } from "@/utils/debug/logger";
import { sendToSentry } from "@/utils/sentry";
import { getToken, removeToken, setToken } from "@/utils/tokenManager";

import { useAccountGlobal } from "@/api/hooks/useAccount";

import { apiBase, fetchWithAuth } from "../core/apiClient";
import {
  AppleLoginRequest,
  ChangeEmailRequest,
  ChangeLanguageRequest,
  ChangePasswordRequest,
  ConfirmEmailChangeRequest,
  ConfirmEmailChangeResult,
  ForgotPasswordRequest,
  GoogleLoginRequest,
  LoginRequest,
  ResetPasswordRequest,
  UnsubscribeResponse,
} from "../models/auth";

interface AuthResponse {
  accessToken: string;
  refreshToken: string;
}

const queryClient = new QueryClient();

/**
 * Generic handler for authentication requests
 */
const handleAuthRequest = async (
  url: string,
  request: LoginRequest | GoogleLoginRequest | AppleLoginRequest,
): Promise<boolean> => {
  return Sentry.startSpan(
    {
      name: `Auth Request ${url}`,
      op: "auth.request",
      attributes: {
        "auth.type": url.includes("google")
          ? "google"
          : url.includes("apple")
            ? "apple"
            : "password",
        "auth.endpoint": url,
      },
    },
    async (span) => {
      let response: Response | undefined;
      let responseData: AuthResponse | undefined;
      let errorData: BackendErrorResponse | undefined;

      try {
        // Clear existing auth state
        removeToken();
        queryClient.clear();
        useHouseholdsGlobal.getState().clear();
        useAccountGlobal.getState().clear();

        response = await fetch(`${apiBase}${url}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(request),
        });

        span?.setAttributes({ "http.status_code": response.status });

        if (response.ok) {
          responseData = (await response.json()) as AuthResponse;
          sendMessageToNative({
            type: "LOGIN",
            accessToken: responseData.accessToken,
            refreshToken: responseData.refreshToken,
          });
          setToken(responseData.accessToken);
          return true;
        }

        errorData = await response.json();
        const authType = url.includes("google")
          ? "google"
          : url.includes("apple")
            ? "apple"
            : "password";
        const errorCode = errorData?.error?.toLowerCase() ?? "";
        const errorDetails = errorData?.message;

        // Handle structured errors from backend
        if (errorCode) {
          const message = getErrorMessage(
            response.status,
            errorCode,
            errorDetails,
            authType,
          );
          throw new ApiError(message);
        }

        // Fallback to status-based errors
        throw new ApiError(getErrorMessage(response.status));
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e));

        sendToSentry(error, {
          url: `${apiBase}${url}`,
          method: "POST",
          response,
          responseBody: responseData,
          additionalContext: {
            component: "handleAuthRequest",
            authType: url.includes("google")
              ? "google"
              : url.includes("apple")
                ? "apple"
                : "password",
            phase: response ? "response-processing" : "request",
            email: "email" in request ? request.email : undefined,
            requestBody: {
              ...request,
              password: undefined,
              credential: undefined,
            },
            errorData,
          },
        });

        throw error;
      }
    },
  );
};

/**
 * Handles standard password-based login
 */
export const login = async (request: LoginRequest): Promise<boolean> => {
  return handleAuthRequest("/auth/login", request);
};

/**
 * Handles Google OAuth login
 */
export const googleLogin = async (
  request: GoogleLoginRequest,
): Promise<boolean> => {
  return handleAuthRequest("/auth/google", request);
};

export const appleLogin = async (
  request: AppleLoginRequest,
): Promise<boolean> => {
  return handleAuthRequest("/auth/apple", request);
};

/**
 * Handles user logout with proper cleanup
 */
export const logout = async (): Promise<boolean> => {
  return Sentry.startSpan(
    {
      name: "Logout",
      op: "auth.logout",
    },
    async () => {
      try {
        sendMessageToNative({
          type: "LOGOUT",
        });

        await fetchWithAuth(`${apiBase}/auth/logout`, {
          method: "POST",
        });

        removeToken();
        routerInstance?.navigate("/login");
        queryClient.clear();
        useHouseholdsGlobal.getState().clear();
        useAccountGlobal.getState().clear();

        return true;
      } catch (error) {
        debug.warn("logout", error);
        const err = error instanceof Error ? error : new Error(String(error));

        sendToSentry(err, {
          additionalContext: {
            component: "logout",
            hadToken: Boolean(getToken()),
          },
        });
        // Force cleanup and redirect even on error
        removeToken();
        routerInstance?.navigate("/login");
        return false;
      }
    },
  );
};

export const changePassword = async (
  request: ChangePasswordRequest,
): Promise<boolean> => {
  try {
    const response = await fetchWithAuth(`${apiBase}/auth/change-password`, {
      method: "POST",
      body: JSON.stringify(request),
    });

    if (response.ok) {
      return true;
    }

    const errorData = (await response.json()) as BackendErrorResponse;
    const message = getErrorMessage(
      response.status,
      errorData.error,
      errorData.message,
    );
    throw new ApiError(message);
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError(getErrorMessage(500));
  }
};

export const forgotPassword = async (
  request: ForgotPasswordRequest,
): Promise<boolean> => {
  try {
    const response = await fetch(`${apiBase}/auth/forgot-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    });

    if (response.ok) {
      return true;
    }

    const errorData = (await response.json()) as BackendErrorResponse;
    const message = getErrorMessage(
      response.status,
      errorData.error,
      errorData.message,
    );
    throw new ApiError(message);
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }

    throw new ApiError(getErrorMessage(500));
  }
};

export const resetPassword = async (
  request: ResetPasswordRequest,
): Promise<boolean> => {
  try {
    const result = await fetch(`${apiBase}/auth/reset-password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(request),
    });

    if (result.ok) {
      return true;
    }

    const errorData = (await result.json()) as BackendErrorResponse;
    const message = getErrorMessage(
      result.status,
      errorData.error,
      errorData.message,
    );
    throw new ApiError(message);
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }

    throw new ApiError(getErrorMessage(500));
  }
};

export const changeEmail = async (
  request: ChangeEmailRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-email`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};

export const confirmEmailChange = async (
  request: ConfirmEmailChangeRequest,
): Promise<ApiResponse<ConfirmEmailChangeResult>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/confirm-email-change`,
    "POST",
    (e) => e as ConfirmEmailChangeResult,
    JSON.stringify(request),
  );
};

export const changeLanguage = async (
  request: ChangeLanguageRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-language`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};

export const unsubscribe = async (
  token: string,
): Promise<ApiResponse<UnsubscribeResponse>> => {
  return fetchUsingApi(
    `${apiBase}/api/v1/notifications/unsubscribe/${token}`,
    "POST",
    (result) => result as UnsubscribeResponse,
  );
};
