import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { unsubscribe } from "@/api/rest/services/authService";
import DobuLogo from "@/assets/dobu-logo.svg";
import Spinner from "@/components/common/Spinner";

import ConfirmationDialog from "./common/ConfirmationDialog";

export default function UnsubscribePage() {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [hasConfirmed, setHasConfirmed] = useState(false);

  // Query to handle unsubscribe API call
  const { data, isLoading, error } = useQuery({
    queryKey: ["unsubscribe", token],
    queryFn: async () => {
      if (!token) {
        throw new Error("No token provided");
      }
      return unsubscribe(token);
    },
    enabled: hasConfirmed, // Only run query after user confirms
    retry: false,
  });

  // Handle confirmation
  const handleConfirm = useCallback(() => {
    setShowConfirmation(false);
    setHasConfirmed(true);
  }, []);

  const handleCancel = useCallback(() => {
    window.location.href = "/";
  }, []);

  // Format the date for display
  const formattedUsedDate = useMemo(() => {
    if (data?.result?.usedAt) {
      const date = new Date(data.result.usedAt);
      return date.toLocaleDateString(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
    return null;
  }, [data?.result?.usedAt]);

  // Determine message and status based on API response
  const { message, status } = useMemo(() => {
    if (!data?.success || error) {
      if (data?.result?.error === "already_used") {
        return {
          message: t("notifications.alreadyUnsubscribed", {
            date: formattedUsedDate,
          }),
          status: "warning",
        };
      }
      if (data?.result?.error === "invalid_token") {
        return {
          message: t("notifications.invalidUnsubscribeToken"),
          status: "error",
        };
      }
      return {
        message: t("notifications.unsubscribeErrorGeneric"),
        status: "error",
      };
    }
    return {
      message: t("notifications.unsubscribeSuccess"),
      status: "success",
    };
  }, [data, error, formattedUsedDate, t]);

  // Status-specific styles
  const statusStyles = useMemo(
    () => ({
      success: "text-green-600",
      error: "text-red-600",
      warning: "text-amber-600",
    }),
    [],
  );

  // Render content based on loading/response state
  const getContent = useCallback(() => {
    if (!hasConfirmed) {
      return (
        <div className="text-center">
          <h2 className="text-xl font-bold mb-4 text-gray-700">
            {t("notifications.unsubscribeTitle")}
          </h2>
          <p className="text-gray-600">
            {t("notifications.unsubscribeDescription")}
          </p>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="flex flex-col items-center gap-4">
          <Spinner className="w-8 h-8" />
          <p className="text-gray-600">{t("notifications.unsubscribing")}</p>
        </div>
      );
    }

    return (
      <div className="text-center">
        <h2
          className={`text-xl font-bold mb-4 ${
            statusStyles[status as keyof typeof statusStyles]
          }`}
        >
          {message}
        </h2>
        {status === "success" && (
          <p className="text-gray-600">
            {t("notifications.unsubscribeSuccessDetail")}
          </p>
        )}
      </div>
    );
  }, [isLoading, message, status, statusStyles, t, hasConfirmed]);

  return (
    <>
      <ConfirmationDialog
        isOpen={showConfirmation}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      <main className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
          <div className="flex flex-col items-center mb-8">
            <img src={DobuLogo} alt="Dobu Logo" className="h-8 mb-4 invert" />
          </div>
          {getContent()}
          {(status === "error" || status === "warning") && (
            <button
              onClick={() => (window.location.href = "/")}
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors w-full"
            >
              {t("common.returnToHome")}
            </button>
          )}
        </div>
      </main>
    </>
  );
}
