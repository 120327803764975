import dayjs from "dayjs";
import i18next from "i18next";

import { changeLanguage as changeLanguageAPI } from "@/api/rest/services/authService";

/**
 * Changes the application language, updates dayjs locale, and syncs with the backend
 *
 * @param language - The language code to change to (e.g., 'en', 'et', 'lv')
 * @returns Promise that resolves when all language change operations are complete
 */
export const changeLanguage = async (language: string): Promise<void> => {
  try {
    await i18next.changeLanguage(language);
    dayjs.locale(language);

    // Sync with backend
    await changeLanguageAPI({ language });
  } catch (error) {
    console.error("Failed to change language:", error);
    throw error;
  }
};
