import i18n from "@/i18n";

export type ApiResponse<Type> =
  | {
      success: true;
      result: Type;
    }
  | {
      success: false;
      message: string;
      result: undefined;
    };

export interface AuthResponse {
  accessToken: string;
}

export interface CustomRequestInit extends RequestInit {
  headers?: HeadersInit;
}

export interface FetchWithAuthOptions extends CustomRequestInit {
  requestContext?: string; // e.g., 'AUTH', 'USER_SETTINGS', etc.
  errorHandlers?: {
    [statusCode: number]: (response: Response, responseBody: any) => void;
  };
}

export class ApiError extends Error {
  constructor(public message: string) {
    super(message);
  }

  toString(): string {
    return this.message;
  }
}

export interface BackendErrorResponse {
  error: string;
  message: string;
  timestamp: string; // ISO 8601 string from Instant
}

export const getErrorMessage = (
  status: number = 500,
  errorCode?: string,
  details?: string,
  authType: string = "default",
): string => {
  const messageConfig = errorCode ? errorMessages[errorCode] : undefined;
  const translationKey = messageConfig?.[authType] || messageConfig?.default;

  if (translationKey) {
    return i18n.t(translationKey, {
      defaultValue: details || i18n.t("common.error.generic"),
    });
  }

  return getStatusBasedMessage(status);
};

const errorMessages: Record<string, Record<string, string>> = {
  private_relay_not_allowed: {
    apple: "login.error.privateRelayNotAllowed",
  },
  social_user_not_registered: {
    default: "login.error.socialUserNotRegistered",
  },
  user_not_found: {
    default: "login.error.userNotFound",
  },
  invalid_credentials: {
    default: "login.loginError",
  },
  token_invalid: {
    default: "login.loginError",
  },
  token_expired: {
    default: "login.error.tokenExpired",
  },
};

const getStatusBasedMessage = (status?: number): string => {
  switch (status) {
    case 401:
    case 403:
      return i18n.t("login.loginError");
    case 500:
      return i18n.t("common.error.500");
    default:
      return i18n.t("common.error.500");
  }
};
