import styles from "./GoogleSignInButton.module.css";

interface GoogleSignInButtonProps {
  onClick: () => void;
}

export const GoogleSignInButton = ({ onClick }: GoogleSignInButtonProps) => {
  return (
    <button type="button" className={styles.googleButton} onClick={onClick}>
      Sign in with Google
    </button>
  );
};
