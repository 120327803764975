import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";

import { DocumentTextIcon, SparklesIcon } from "@heroicons/react/24/outline";

import { PaperAirplaneIcon, XCircleIcon } from "@heroicons/react/24/solid";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { Document } from "@/api/rest/models/document";
import {
  askQuestion,
  EmitterData,
  EmitterDataTag,
} from "@/api/rest/services/chatService.ts";
import { downloadDocument2 } from "@/api/rest/services/documentService";
import DobuLogo from "@/assets/dobu-logo.svg";
import ChatField from "@/components/chat/ChatField.tsx";
import Spinner from "@/components/common/Spinner.tsx";
import { DocumentsPreview } from "@/components/household-contents/document/DocumentsPreview.tsx";

import {
  ChatMessage,
  ChatMessageHolder,
  ChatMessageType,
} from "../common/ChatMessage.tsx";

interface ChatMessageItem {
  message: string;
  isResponse: boolean;
  linkedDocuments: Document[];
}

export default function ChatPopup({
  className,
  householdId,
  documents,
}: {
  className?: string;
  householdId: number;
  documents: Document[];
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessageItem[]>([]);
  const [currentResponseInProcess, setCurrentResponseInProcess] = useState<
    string | null
  >(null);

  const scrollRef = useRef<HTMLDivElement>(null);
  const insideInputRef = useRef<HTMLInputElement>(null);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const QUESTION_PRESETS = useMemo(
    () => [t("chat.pin1"), t("chat.pin2"), t("chat.pin3")],
    [t],
  );

  const responseCallback = useCallback(
    (response: string, isComplete: boolean, tagsFound: EmitterData[]) => {
      if (isComplete) {
        const foundDocuments = tagsFound
          .filter((tag) => tag.tag === EmitterDataTag.DOCUMENTS)
          .flatMap((tag) => tag.data.split(","))
          .map(Number)
          .map((id) => documents.find((doc) => doc.id === id))
          .filter((it) => it !== undefined)
          .map((it) => it);

        setChatHistory((oldHistory) => [
          ...oldHistory,
          {
            message: response,
            isResponse: true,
            linkedDocuments: foundDocuments,
          },
        ]);
        setCurrentResponseInProcess(null);
        setTimeout(
          () => scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight),
          100,
        );
      } else {
        setCurrentResponseInProcess(response);
        scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight);
      }
    },
    [documents],
  );

  const submitQuestion = useCallback(
    async (startFresh: boolean, rawQuestion?: string) => {
      if (!isOpen) {
        setIsOpen(true);
      }
      const question = rawQuestion ?? insideInputRef.current?.value;
      if (insideInputRef.current) {
        insideInputRef.current.value = "";
      }
      if (!question || question.trim() === "") {
        return;
      }
      const newMessage = {
        message: question,
        isResponse: false,
        linkedDocuments: [],
      };
      setCurrentResponseInProcess("");
      if (startFresh) {
        setChatHistory([newMessage]);
      } else {
        setChatHistory((oldHistory) => [...oldHistory, newMessage]);
      }
      await askQuestion(householdId, question, startFresh, responseCallback);
    },
    [householdId, isOpen, responseCallback, insideInputRef],
  );

  const closeChat = useCallback(() => {
    setIsOpen(false);
    setChatHistory([]);
  }, []);

  // Hacky resize listener for mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        setViewportHeight(window.visualViewport.height);
      }
    };

    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", handleResize);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  return (
    <div className={className}>
      <div className="flex gap-3 sm:gap-2 pb-4 flex-wrap w-full">
        {QUESTION_PRESETS.map((preset, i) => (
          <div
            key={i}
            className="py-2 px-4 sm:py-1 sm:px-3 rounded-full border border-orange-300 bg-white text-sm text-gray-600 hover:bg-orange-100 hover:cursor-pointer"
            onClick={() => submitQuestion(true, preset)}
          >
            {preset}
          </div>
        ))}
      </div>
      <ChatField submitQuestion={submitQuestion} />

      {/* TODO: on close nullify the session! */}
      <Dialog open={isOpen} onClose={closeChat} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
          <DialogPanel
            className="mx-auto md:rounded-lg bg-white flex flex-col shadow-xl overflow-clip w-full h-full md:w-[75%] lg:w-[50%] md:h-[80vh] fixed bottom-0"
            style={{
              height: isOpen ? `${viewportHeight}px` : "auto", // `300px` : "auto",
              transition: "height 0.25s ease-out",
            }}
          >
            <DialogTitle className="flex-none text-lg p-6 font-medium leading-6 text-gray-900 flex flex-row justify-between items-center border-b">
              <SparklesIcon className="w-6 h-6 inline mr-2" />
              <img
                className="invert"
                src={DobuLogo}
                alt="Logo"
                width={88}
                height={16}
              />
              <XCircleIcon
                className="h-6 w-6 text-gray-500 hover:text-dobu-orange-focus focus-visible:text-dobu-orange-focus cursor-pointer"
                onClick={closeChat}
              />
            </DialogTitle>

            <div className="flex flex-col flex-1 min-h-0">
              <ChatMessageHolder
                scrollRef={scrollRef}
                className="flex-1 overflow-y-auto"
              >
                {chatHistory.map((msg, i) => (
                  <ChatMessage
                    key={i}
                    message={msg.message}
                    type={
                      msg.isResponse
                        ? ChatMessageType.RESPONSE
                        : ChatMessageType.QUESTION
                    }
                  >
                    {msg.linkedDocuments.length > 0 && (
                      <div className="flex flex-row flex-wrap gap-2">
                        {msg.linkedDocuments.map((doc) => (
                          <DocumentsPreview
                            key={doc.id}
                            className="bg-white hover:bg-orange-100 p-2 rounded"
                            document={doc}
                            onSelect={downloadDocument2}
                            allowPreview
                          >
                            <span>
                              <DocumentTextIcon className="h-4 w-4 -mt-0.5 inline-block" />{" "}
                              {doc.fileName}
                            </span>
                          </DocumentsPreview>
                        ))}
                      </div>
                    )}
                  </ChatMessage>
                ))}
                {currentResponseInProcess && (
                  <ChatMessage
                    message={currentResponseInProcess}
                    type={ChatMessageType.RESPONSE_IN_PROCESS}
                  />
                )}
              </ChatMessageHolder>

              {/* Input area - Fixed height */}
              <label className="flex relative self-stretch m-2 items-center">
                {currentResponseInProcess !== null ? (
                  <Spinner className="w-6 h-6 inline mr-2 absolute right-0 pointer-events-none" />
                ) : (
                  <PaperAirplaneIcon
                    className="w-6 h-6 inline mr-2 absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-300 hover:text-dobu-orange cursor-pointer"
                    onClick={() => submitQuestion(false)}
                  />
                )}
                <input
                  className="border border-gray-300 rounded-md p-2 flex-grow"
                  name="question"
                  type="text"
                  placeholder={t("chat.enterQuestion")}
                  ref={insideInputRef}
                  onKeyUp={(e) =>
                    currentResponseInProcess === null &&
                    e.key === "Enter" &&
                    submitQuestion(false)
                  }
                />
              </label>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  );
}
